import './index.scss'

$('footer .backtop').on('click',function(){
  $("html, body").animate({
    scrollTop: 0
  }, {duration: 500,easing: "swing"});
  return false;
})

$('footer .footer-nav-mob ul li .title .iconfont').on('click',function(){
  let $parent = $(this).closest('li')
  if($parent.hasClass('active')){
    $parent.removeClass('active').find('.sub-nav').slideUp()
  }else{
    $parent.siblings().removeClass('active').find('.sub-nav').slideUp()
    $parent.addClass('active').find('.sub-nav').slideDown()
  }
})

$(document).ready(function(){
  $('.footer-nav-mob ul li').each(function(index){
    if($(this).find('.sub-nav a').length > 0){
      $(this).find('.title .iconfont').css('display','flex')
    }
  })

  $('.footer-contact .link a').on('click',function(){
    $('.contact-dialog').fadeIn(300)
  })
  $('.contact-dialog .close-btn').on('click',function(){
    $('.contact-dialog').fadeOut(300)
  })
})

let footervideo = $('.footer-bg video')[0]
$(footervideo).on('loadedmetadata',function(){
  footervideo.playbackRate = 0.7
})